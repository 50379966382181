import React from "react"
import { Link } from "gatsby"

import { Tab, Tabs, TabList, TabPanel } from "react-tabs"
import "react-tabs/style/react-tabs.css"

import Layout from "../../components/bs/layout"
//import Image from "../components/image"
import SEO from "../../components/bs/seo"

import MainImg from "../../images/main_bg.jpg"

import shape1 from "../../images/dec/1.png"
import shape2 from "../../images/dec/2.png"
import shape3 from "../../images/dec/3.png"
import shape4 from "../../images/dec/4.png"
import shape5 from "../../images/dec/5.png"
import shape6 from "../../images/dec/6.png"
import shape7 from "../../images/dec/7.png"
import shape8 from "../../images/dec/8.png"

import product1 from "../../images/moonamar-hladno-cijedeno-ulje-corokota-crnog-kima.png"
import product2 from "../../images/moonamar-hladno-cijedeno-bundevino-bucino-ulje.png"
import product3 from "../../images/moonamar-konopljino-ulje-iz-organskog-bio-sjemena.png"
import product4 from "../../images/moonamar-hladno-cijedeno-djevicansko-laneno-ulje.png"

const BundevinoUlje = () => (
  <Layout>
    <SEO
      title="IMMMUNO NATURA - HLADNO CIJEĐENO BUNDEVINO (BUĆINO) ULJE "
      description="IMMMUNO NATURA hladno cijeđeno bundevino (bućino) ulje - prirodno ulje koje se ne izlaže nikakvom dodatnom procesiranju."
    />

    <section
      id="_heroPage"
      className="hero is-large"
      style={{ backgroundImage: `url(${MainImg})` }}
    >
      <div id="bgHero">
        <span className="shape1">
          <img src={shape1} alt="Shape 1" />
        </span>
        <span className="shape2">
          <img src={shape2} alt="Shape 2" />
        </span>
        <span className="shape3">
          <img src={shape3} alt="Shape 3" />
        </span>
        <span className="shape4">
          <img src={shape4} alt="Shape 4" />
        </span>
        <span className="shape5">
          <img src={shape5} alt="Shape 5" />
        </span>
        <span className="shape6">
          <img src={shape6} alt="Shape 6" />
        </span>
        <span className="shape7">
          <img src={shape7} alt="Shape 7" />
        </span>
        <span className="shape8">
          <img src={shape2} alt="Shape 8" />
        </span>
      </div>
      <div className="hero-body" />
    </section>

    <span className="_line" />

    <section id="_oProizvodu" className="section">
      <div className="container">
        <div className="columns">
          <div className="column">
            <div className="_slide">
              <img
                className="_img"
                src={product2}
                alt="HLADNO CIJEĐENO BUNDEVINO (BUĆINO) ULJE"
                title="HLADNO CIJEĐENO BUNDEVINO (BUĆINO) ULJE"
              />
              <div className="_info">
                <div className="_inner">
                  <span>IMMMUNO NATURA</span>
                  <div className="title">Bundevino (bučino) ulje</div>
                  <div className="subtitle">
                    Blagodat iz prirode za sredovječnu i stariju mušku
                    populaciju
                  </div>
                  <p className="content">
                    Mnogobrojna naučna istraživanja urađena na sredovječnoj i
                    starijoj muškoj populaciji ukazuju na njegovo blagotvorno
                    dejstvo na prostatu. Redovna konzumacija pomaže organizmu
                    kod problema povećane prostate.
                  </p>
                  <div className="price">21,90 KM</div>
                </div>
                <div className="_buttonShop">
                  <a href="https://zdravo.ba/proizvod/zdravlje/moonamar/moonamar-undevino-ulje-(-blagodat-za-prostatu-)">
                    KUPI PROIZVOD
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section id="_pageContent" className="section">
      <span className="_dec2">
        <img src={shape8} alt="Dec 2" />
      </span>
      <div className="container">
        <div className="columns">
          <div className="column is-8 is-offset-2">
            <Tabs>
              <TabList>
                <Tab>Opis proizvoda</Tab>
                <Tab>Sastojci i upotreba</Tab>
                <Tab>Prirodni talenat</Tab>
              </TabList>

              <TabPanel>
                <div className="content">
                  <p>
                    IMMMUNO NATURA bundevino ulje se dobija procesom hladnog
                    cijeđenja sirovih, neprženih sjemenki bundeve i ne podvrgava
                    se nikakvim dodatnim doradama, čime se čuvaju nutritivno
                    vrijedne materije iz sjemenki i proizvod zadržava potpun
                    spektar korisnih blagotvornih svojstava.
                  </p>
                  <p>
                    <strong>PROCES CIJEĐENJA</strong>: hladno cijeđeno iz
                    neprženih sjemenki bundeve.
                    <br />
                    <strong>KVALITET</strong>: nerafinisano, 100% prirodno.
                    <br />
                    <strong>VRSTA SJEMENA</strong>: Cucurbita pepo L.
                    <br />
                    <strong>NAČIN PROIZVODNJE</strong>: prirodan, bez dodatnog
                    procesiranja.
                    <br />
                    <strong>DODACI PREPARATU</strong>: bez ikakvih dodatnih
                    sastojaka, 100% sjemenke bundeve.
                    <br />
                    <strong>STABILNOST</strong>: zbog visokog sadržaja
                    nezasićenih masnih kiselina nije stabilno na
                    povi&scaron;enim temperaturama, te se ne preporučuje za
                    kuhanje i prženje.
                    <br />
                    <strong>SKLADI&Scaron;TENJE</strong>: nakon otvaranja čuvati
                    u frižideru i upotrijebiti u roku od 2-3 mjeseca.
                  </p>
                  <table>
                    <tbody>
                      <tr>
                        <td width="168">
                          <p>
                            <strong>Nutritivna vrijednost</strong>
                          </p>
                        </td>
                        <td width="132">
                          <p>
                            <strong>na 100 g</strong>
                          </p>
                        </td>
                        <td width="151">
                          <p>
                            <strong>Na 5 g</strong>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td width="168">
                          <p>Kalorijska vrijednost</p>
                        </td>
                        <td width="132">
                          <p>3700 kJ/900 kcal</p>
                        </td>
                        <td width="151">
                          <p>185 kJ/45 kcal</p>
                        </td>
                      </tr>
                      <tr>
                        <td width="168">
                          <p>Masnoće</p>
                          <p>-zasićene masne kiseline</p>
                          <p>-nezasićene masne kiseline</p>
                        </td>
                        <td width="132">
                          <p>99 g</p>
                          <p>17 g</p>
                          <p>82 g</p>
                        </td>
                        <td width="151">
                          <p>4,95 g</p>
                          <p>0,85 g</p>
                          <p>4,1 g</p>
                        </td>
                      </tr>
                      <tr>
                        <td width="168">
                          <p>Ugljikohidrati</p>
                        </td>
                        <td width="132">
                          <p>0 g</p>
                        </td>
                        <td width="151">
                          <p>0 g</p>
                        </td>
                      </tr>
                      <tr>
                        <td width="168">
                          <p>Bjelančevine</p>
                        </td>
                        <td width="132">
                          <p>0 g</p>
                        </td>
                        <td width="151">
                          <p>0 g</p>
                        </td>
                      </tr>
                      <tr>
                        <td width="168">
                          <p>Holesterol</p>
                        </td>
                        <td width="132">
                          <p>0 g</p>
                        </td>
                        <td width="151">
                          <p>0 g</p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="content">
                  <h3>SASTAV</h3>
                  <p>
                    Nutritivna i upotrebna vrijednost bundevinih sjemenki
                    zasnovana je na visokom sadržaju proteina i značajnom
                    energetskom potencijalu zbog visokog sadržaja ulja. Od
                    mineralnih materija bundevine sjemenke su posebno bogate
                    fosforom, kalijem i magnezijem. Iako su prisutni u malim
                    količinama, značajni su i kalcijum, željezo i bakar.
                    Ispitivan je uticaj bundevinih sjemenki na nutritivni
                    sadržaj unijetog željeza i na hematolo&scaron;ke
                    karakteristike na ženama starosne dobi od 20 do 37 godina,
                    te je utvrđeno da su bundevine sjemenke obezbijedile povećan
                    unos željeza i nakon četiri sedmice konzumiranja ovih
                    sjemenki u količini od 30 g dnevno, postignuto je značajno
                    pobolj&scaron;anje u koncentraciji željeza u serumu krvi.
                  </p>
                  <p>
                    Osim navedenih mineralnih materija, bundevine sjemenke su
                    dobar izvor i vitamina B skupine: B<sub>1</sub>, B
                    <sub>2</sub>, B<sub>3</sub> i B<sub>6</sub>.
                  </p>
                  <p>
                    Sjemenke zahvaljujući sadržaju celuloze posjeduju purgativno
                    svojstvo tj. imaju ulogu &bdquo;čistača&ldquo; organizma, a
                    imaju također i diuretsko djelovanje, djeluju umirujuće i
                    olak&scaron;avajuće. U zimskom periodu pri konzumiranju
                    sjemenki bundeve, zahvaljujući vitaminima i mineralima
                    povećava se otpornost organizma prema infekcijama, stiče se
                    mentalna svježina i vitalnost.
                  </p>
                  <p>
                    Iako bogato vi&scaron;estruko nezasićenim masnim kiselinama,
                    pod uslovom da je pravilno skladi&scaron;teno, bundevino
                    ulje znatno je manje podložno oksidaciji i pojavi užeglosti
                    od sličnih ulja. Tome pridonosi i antioksidativno djelovanje
                    prisutnih vitamina A i E, koji isti učinak imaju i u
                    na&scaron;em organizmu &scaron;titeći nas od
                    o&scaron;tećenja nastalih djelovanjem slobodnih radikala.
                  </p>
                  <h3>UPOTREBA</h3>
                  <p>
                    S obzirom na to da pripada uljima sa visokim sadržajem
                    nezasićenih masnih kiselina, bundevino ulje se kvalifikuje
                    kao idealna komponenta nutritivno i biolo&scaron;ki vrijedne
                    ishrane. Upravo iz tog razloga bundevino ulje se preporučuje
                    kao proizvod za svakodnevnu upotrebu, koji značajno
                    doprinosi pobolj&scaron;anju općeg zdravstvenog stanja
                    ljudi. Kako u obliku dodatka ishrani tako i kao dodatak
                    jelima i salatam može obogatiti ukuse Va&scaron;e kuhinje i
                    svakodnevne ishrane.
                  </p>
                  <p>
                    <strong>Upotreba kod uvećane prostate:</strong> tri puta
                    dnevno jedna supena ka&scaron;ika, u trajanju od 3 do 4
                    mjeseca.
                  </p>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="content">
                  <h3>Prirodni talenat za snižavanje holesterola</h3>
                  <p>
                    Za mnoge fitosterole i fitostanole se smatra da imaju efekat
                    snižavanja holesterola. Uključivanje fitosterola u ishranu,
                    po svemu sudeći, ima isti značaj za snižavanje nivoa
                    holesterola kao i smanjenje unosa zasićenih masti. S obzirom
                    na to da su fitosteroli prisutni, kako u bundevinim
                    sjemenkama tako i u bundevinom ulju, može se smatrati da ove
                    komponente zajedno sa visokim sadržajem linolne kiseline
                    mogu ispoljavati pozitivne zdravstvene efekte kod bolesti
                    kao &scaron;to je ateroskleroza.
                  </p>
                  <p>
                    <strong>
                      Prirodni talenat za ublažavanje simptoma uvećane prostate{" "}
                    </strong>
                    &ndash;<strong> benigne hiperplazije </strong>
                  </p>
                  <p>
                    Najče&scaron;će spominjano blagotvorno dejstvo bundevinog
                    ulja vezano je za smanjenje tegoba uzrokovanih uvećanjem
                    prostate &ndash; benignom hiperplazijom. Radi se o učestalom
                    benignom tumoru kod mu&scaron;karaca, najče&scaron;će
                    starijih od 50 godina, koji uzrokuje raznovrsnu urinarnu
                    simptomatologiju. Najvažniji aktivni sastojci bundevinog
                    ulja koji djeluju na regulaciju tonusa muskulature
                    be&scaron;ike i ublažava subjektivne simptome pri uvećanoj
                    prostati su fitosteroli i to &nbsp;∆7-sterol.
                  </p>
                  <p>
                    Bundevinom ulju se pripisuje i svojstvo jačanja potencije,
                    ubrzavanja postoperativne rekonvalescencije i
                    pobolj&scaron;anja op&scaron;teg stanja organizma.
                  </p>
                </div>
              </TabPanel>
            </Tabs>
          </div>
        </div>
      </div>
      <span className="_dec3">
        <img src={shape6} alt="Dec 3" />
      </span>
    </section>

    <section id="_listProduct" className="section">
      <div className="container">
        <div className="columns">
          <div className="column is-8 is-offset-2">
            <nav className="level">
              <div className="level-item has-text-centered">
                <div>
                  <Link to="/bs/proizvod/corokotovo-ulje">
                    <figure className="image is-4by5">
                      <img
                        src={product1}
                        alt="HLADNO CIJEĐENO ULJE ČOROKOTA (CRNOG KIMA)"
                        title="HLADNO CIJEĐENO ULJE ČOROKOTA (CRNOG KIMA)"
                      />
                    </figure>
                  </Link>
                </div>
              </div>
              <div className="level-item has-text-centered">
                <div>
                  <Link to="/bs/proizvod/bundevino-ulje">
                    <figure className="image is-4by5">
                      <img
                        src={product2}
                        alt="HLADNO CIJEĐENO BUNDEVINO (BUĆINO) ULJE"
                        title="HLADNO CIJEĐENO BUNDEVINO (BUĆINO) ULJE"
                      />
                    </figure>
                  </Link>
                </div>
              </div>
              <div className="level-item has-text-centered">
                <div>
                  <Link to="/bs/proizvod/konopljino-ulje">
                    <figure className="image is-4by5">
                      <img
                        src={product3}
                        alt="KONOPLJINO ULJE IZ ORGANSKOG (BIO) SJEMENA"
                        title="KONOPLJINO ULJE IZ ORGANSKOG (BIO) SJEMENA"
                      />
                    </figure>
                  </Link>
                </div>
              </div>
              <div className="level-item has-text-centered">
                <div>
                  <Link to="/bs/proizvod/laneno-ulje">
                    <figure className="image is-4by5">
                      <img
                        src={product4}
                        alt="HLADNO CIJEĐENO, DJEVIČANSKO LANENO ULJE"
                        title="HLADNO CIJEĐENO, DJEVIČANSKO LANENO ULJE"
                      />
                    </figure>
                  </Link>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default BundevinoUlje
